import React from "react"
import HeaderCloud from "./HeaderCloud"

import CloudServerFeatures from "./CloudServerFeatures"
import CloudConfig from "./Cloudconfig"
import FaqCloudServer from "./FaqCloudServer"
import Seo from "../../../components/seo"

const ManagedServerPage = () => {
  return (
    <div className="content bg">
      <Seo
        title="Managed VPS or Cloud Server"
        desc="Motherepos offers Cloud Server, Cloud Disaster Recovery Services, Database Server &amp; Bigdata and many other cloud based services."
        keywords="Best cloud Server, point of sale database server"
      />
      <HeaderCloud />
      <section className="pricing cloud-server-plans bg-light">
        <div className="container-xl">
          <h2 className="component-title d-inline-block py-4">Cloud Server</h2>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <CloudConfig />
            </div>
          </div>
        </div>
      </section>
      <CloudServerFeatures />
      <FaqCloudServer />
    </div>
  )
}

export default ManagedServerPage
