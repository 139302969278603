import React from "react"
import Layout from "../../components/layout"
import CloudServerPage from "../../components/cloud/cloud-server/managed-server"
const CloudServer = () => {
  return (
    <Layout>
      <CloudServerPage />
    </Layout>
  )
}

export default CloudServer
